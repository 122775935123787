import React from 'react';
import { HOST_PREFIX, HOST_SUFFIX } from './constants';
import {Redirect} from 'react-router-dom';
import Cookies from 'universal-cookie';
const BUILTIN_VERBS = ["get", "post"];

const cookies = new Cookies();

/** Fetch enchancing function for calling apis.
 * @param {String} url - url to get resource.
 * @param {Object} options - options object used in fetch.
 * @param {Object} options.json - options object used in fetch.
 * @returns {Promise} resolved returned by fetch().
 */
async function useApi(url, options) {
    options = options || {};
    options.headers = options.headers || {};

    if (options.data) {
        options.body = JSON.stringify(options.data);
        options.headers["Content-Type"] = "application/json";
        delete options.data;
    }

    if (options.auth) {
        options.auth = {
          type: "Bearer",
          token: cookies.get('token') || ""
        };

        options.headers.Authorization = `${options.auth.type} ${options.auth.token}`;

        delete options.auth;
    }

    return fetch(`${HOST_PREFIX}${HOST_SUFFIX}${url}`, options)
        .then(response => {

            if (options.json) {
                return response.json();
            }

            return response;
        })
        .catch(error => {
            throw error;
        });
}

export default useApi;
