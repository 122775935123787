import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';

import ProtectedRoute from './containers/DefaultLayout/ProtectedRoute'
import { AuthProvider } from './containers/DefaultLayout/AuthContext'

import './App.scss';

const loading = () => <div className="animated fadeIn pt-3 text-center"><div className="sk-spinner sk-spinner-pulse"></div></div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./views/Login'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword/ForgotPassword.js'));
const ResetPassword = React.lazy(() => import('./views/ForgotPassword/ResetPassword.js'));

class App extends Component {

  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <AuthProvider>
            <Switch>
              <Route path="/login" render={props => <Login {...props}/>} />
              <Route path="/forgot-password" render={props => <ForgotPassword {...props}/>} />
              <Route path="/reset-password/:token" render={props => <ResetPassword {...props}/>} />
              <ProtectedRoute path="/" name="Home" component={DefaultLayout} />
            </Switch>
          </AuthProvider>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
